import React from 'react';
import StandardPage from '../components/page/page-standard';

const pageName = 'vacancy-page';

const VacancyPage = () => (
  <StandardPage pageName={pageName} title="Грумер" backHref="/">

    <div className="page__limited-text">

      <p className="par">
        Ищем грумера с хорошим «промо»-опытом в вебе, который с любовью относится к айдентике
        <span role="img" aria-label="">👈</span>
        Очень пригодится всё,
        что можно назвать граф.дизайном в цифровой среде. Рассчитываем, что он станет генератором концептов, визуалов
        и
        графики. которые будут использованы для коммуникации с аудиторией в онлайне и офлайне, в интерфейсах и
        спецпроектах Mail.ru и Mail.ru Group.
        <span role="img" aria-label="">🤙</span>
        Кстати, опыт работы в агентстве, студии, продакшене будет плюсом.
        Общение получится более продуктивным со ссылкой на портфолио и резюме.
      </p>

      <p className="par">
        Что нужно будет делать:
        <br />
        — генерировать идеи, key visual для оформления спецпроектов и мероприятий;
        <br />
        — делать промо-сайты и маркетинговые материалы для наших продуктов;
        <br />
        — поддержать и развивать визуальный язык бренда;
        <br />
        — подхватывать периодические задачи по интерфейсам;
        <br />
        — помогать с контентом для соцсетей.
        <br />
        Хотим видеть дизайнера, который:
        <br />
        — имеет релевантный агентский опыт или решения смежных задач в продуктовых компаниях;
        <br />
        — аккуратно относится к деталям и умеет работать с типограф
        <br />
        — проявляет инициативу и понимает свою ответственность перед коллегами и аудиторией;
        <br />
        — знает как работать с вебом и мобильными приложениями;
        <br />
        — не поленится выполнить тестовое задание.
      </p>

      <p className="par">
        Что мы можем предложить:
        <br />
        — разнообразие типов задач, не хуже чем в агентстве;
        <br />
        — большую команду дизайнеров для обмена опытом и уютную Команду Креатива;
        <br />
        — круглогодичные дизайнерские мероприятия и тренинги внутри компании;
        <br />
        — топовое оборудование;
        <br />
        — поездки на конференции;
        <br />
        — заработную плату, которую заслуживает такой крутой специалист;
        <br />
        — офис в 5 минутах от метро Аэропорт, в котором есть всё (от бесплатной парковки и тренажерного зала до
        салона красоты и химчистки);
        <br />
        — дизайнерам из других городов помогаем с переездом.
      </p>

      <p className="par">
        <span role="img" aria-label="">👉</span>
        Присылайте портфолио и резюме на
        <a href="mailto:d.osadchuk@corp.mail.ru">d.osadchuk@corp.mail.ru</a>
        .
        <br />
        Количество проектов, в которые мы вовлечены, растёт очень бодро и интересных, разнообразных задач для развития в
        крепкой команде будет хватать.
        <span role="img" aria-label="">🙌</span>
      </p>
    </div>

  </StandardPage>
);

export default VacancyPage;
